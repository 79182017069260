import { isString } from './nodash';
import hasOwnProperty from './hasOwnProperty';
export function isDisabledOption(index, items) {
  var option = items[index];
  return !!option && !isString(option) && hasOwnProperty(option, 'disabled');
}
export function skipDisabledOptions(currentIndex, key, items) {
  var newIndex = currentIndex;

  while (isDisabledOption(newIndex, items)) {
    newIndex += key === 'ArrowUp' ? -1 : 1;
  }

  return newIndex;
}
export default function getUpdatedActiveIndex(currentIndex, key, items) {
  var newIndex = currentIndex; // Increment or decrement index based on user keystroke.

  newIndex += key === 'ArrowUp' ? -1 : 1; // Skip over any disabled options.

  newIndex = skipDisabledOptions(newIndex, key, items); // If we've reached the end, go back to the beginning or vice-versa.

  if (newIndex === items.length) {
    newIndex = -1;
  } else if (newIndex === -2) {
    newIndex = items.length - 1; // Skip over any disabled options.

    newIndex = skipDisabledOptions(newIndex, key, items);
  }

  return newIndex;
}