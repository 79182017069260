export default function isShown(_ref) {
  var open = _ref.open,
      minLength = _ref.minLength,
      showMenu = _ref.showMenu,
      text = _ref.text;

  // If menu visibility is controlled via props, that value takes precedence.
  if (open || open === false) {
    return open;
  }

  if (text.length < minLength) {
    return false;
  }

  return showMenu;
}