import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["referenceElement"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import PropTypes from 'prop-types';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { noop } from '../../utils';
var alignValues = ['justify', 'left', 'right'];
// `Element` is not defined during server-side rendering, so shim it here.

/* istanbul ignore next */
var SafeElement = typeof Element === 'undefined' ? noop : Element;
var propTypes = {
  /**
   * Specify menu alignment. The default value is `justify`, which makes the
   * menu as wide as the input and truncates long values. Specifying `left`
   * or `right` will align the menu to that side and the width will be
   * determined by the length of menu item values.
   */
  align: PropTypes.oneOf(alignValues),
  children: PropTypes.func.isRequired,

  /**
   * Specify whether the menu should appear above the input.
   */
  dropup: PropTypes.bool,

  /**
   * Whether or not to automatically adjust the position of the menu when it
   * reaches the viewport boundaries.
   */
  flip: PropTypes.bool,
  isMenuShown: PropTypes.bool,
  positionFixed: PropTypes.bool,
  // @ts-ignore
  referenceElement: PropTypes.instanceOf(SafeElement)
};
var defaultProps = {
  align: 'justify',
  dropup: false,
  flip: false,
  isMenuShown: false,
  positionFixed: false
};
var setPopperWidth = {
  enabled: true,
  fn: function fn(data) {
    // eslint-disable-next-line no-param-reassign
    data.state.styles.popper.width = "".concat(data.state.rects.reference.width, "px");
  },
  name: 'setPopperWidth',
  phase: 'write'
};
export function getModifiers(props) {
  var modifiers = [{
    enabled: !!props.flip,
    name: 'flip'
  }];

  if (props.align !== 'right' && props.align !== 'left') {
    modifiers.push(setPopperWidth);
  }

  return modifiers;
}
export function getPlacement(props) {
  var x = props.align === 'right' ? 'end' : 'start';
  var y = props.dropup ? 'top' : 'bottom';
  return "".concat(y, "-").concat(x);
}

var Overlay = function Overlay(_ref) {
  var referenceElement = _ref.referenceElement,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      popperElement = _useState2[0],
      attachRef = _useState2[1];

  var _usePopper = usePopper(referenceElement, popperElement, {
    modifiers: getModifiers(props),
    placement: getPlacement(props),
    strategy: props.positionFixed ? 'fixed' : 'absolute'
  }),
      attributes = _usePopper.attributes,
      styles = _usePopper.styles,
      forceUpdate = _usePopper.forceUpdate;

  if (!props.isMenuShown) {
    return null;
  }

  return props.children(_objectSpread(_objectSpread({}, attributes.popper), {}, {
    innerRef: attachRef,
    inputHeight: (referenceElement === null || referenceElement === void 0 ? void 0 : referenceElement.offsetHeight) || 0,
    scheduleUpdate: forceUpdate,
    style: styles.popper
  }));
};

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;
export default Overlay;