import getOptionLabel from './getOptionLabel';

function getInputText(props) {
  var activeItem = props.activeItem,
      labelKey = props.labelKey,
      multiple = props.multiple,
      selected = props.selected,
      text = props.text;

  if (activeItem) {
    // Display the input value if the pagination item is active.
    return getOptionLabel(activeItem, labelKey);
  }

  if (!multiple && selected.length && selected[0]) {
    return getOptionLabel(selected[0], labelKey);
  }

  return text;
}

export default getInputText;