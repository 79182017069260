import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["className", "isInvalid", "isValid", "size"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import cx from 'classnames';
import { isSizeLarge, isSizeSmall } from './size';

/**
 * Returns Bootstrap classnames from `size` and validation props, along
 * with pass-through props.
 */
export default function propsWithBsClassName(_ref) {
  var className = _ref.className,
      isInvalid = _ref.isInvalid,
      isValid = _ref.isValid,
      size = _ref.size,
      props = _objectWithoutProperties(_ref, _excluded);

  return _objectSpread(_objectSpread({}, props), {}, {
    className: cx('form-control', 'rbt-input', {
      'form-control-lg': isSizeLarge(size),
      'form-control-sm': isSizeSmall(size),
      'is-invalid': isInvalid,
      'is-valid': isValid
    }, className)
  });
}