import _defineProperty from "@babel/runtime/helpers/defineProperty";
import PropTypes from 'prop-types';
import { SIZES } from './constants';
import { isFunction, warn } from './utils';
var INPUT_PROPS_BLACKLIST = [{
  alt: 'onBlur',
  prop: 'onBlur'
}, {
  alt: 'onInputChange',
  prop: 'onChange'
}, {
  alt: 'onFocus',
  prop: 'onFocus'
}, {
  alt: 'onKeyDown',
  prop: 'onKeyDown'
}];
export var sizeType = PropTypes.oneOf(SIZES);

/**
 * Allows additional warnings or messaging related to prop validation.
 */
export function checkPropType(validator, callback) {
  return function (props, propName, componentName) {
    PropTypes.checkPropTypes(_defineProperty({}, propName, validator), props, 'prop', componentName);
    isFunction(callback) && callback(props, propName, componentName);
  };
}
export function caseSensitiveType(props) {
  var caseSensitive = props.caseSensitive,
      filterBy = props.filterBy;
  warn(!caseSensitive || typeof filterBy !== 'function', 'Your `filterBy` function will override the `caseSensitive` prop.');
}
export function deprecated(validator, reason) {
  return function (props, propName, componentName) {
    if (props[propName] != null) {
      warn(false, "The `".concat(propName, "` prop is deprecated. ").concat(reason));
    }

    return PropTypes.checkPropTypes(_defineProperty({}, propName, validator), props, 'prop', componentName);
  };
}
export function defaultInputValueType(props) {
  var defaultInputValue = props.defaultInputValue,
      defaultSelected = props.defaultSelected,
      multiple = props.multiple,
      selected = props.selected;
  var name = defaultSelected.length ? 'defaultSelected' : 'selected';
  warn(!(!multiple && defaultInputValue && (defaultSelected.length || selected && selected.length)), "`defaultInputValue` will be overridden by the value from `".concat(name, "`."));
}
export function defaultSelectedType(props) {
  var defaultSelected = props.defaultSelected,
      multiple = props.multiple;
  warn(multiple || defaultSelected.length <= 1, 'You are passing multiple options to the `defaultSelected` prop of a ' + 'Typeahead in single-select mode. The selections will be truncated to a ' + 'single selection.');
}
export function highlightOnlyResultType(_ref) {
  var allowNew = _ref.allowNew,
      highlightOnlyResult = _ref.highlightOnlyResult;
  warn(!(highlightOnlyResult && allowNew), '`highlightOnlyResult` will not work with `allowNew`.');
}
export function ignoreDiacriticsType(props) {
  var filterBy = props.filterBy,
      ignoreDiacritics = props.ignoreDiacritics;
  warn(ignoreDiacritics || typeof filterBy !== 'function', 'Your `filterBy` function will override the `ignoreDiacritics` prop.');
}
export function inputPropsType(_ref2) {
  var inputProps = _ref2.inputProps;

  if (!(inputProps && Object.prototype.toString.call(inputProps) === '[object Object]')) {
    return;
  } // Blacklisted properties.


  INPUT_PROPS_BLACKLIST.forEach(function (_ref3) {
    var alt = _ref3.alt,
        prop = _ref3.prop;
    var msg = alt ? " Use the top-level `".concat(alt, "` prop instead.") : null;
    warn(!inputProps[prop], "The `".concat(prop, "` property of `inputProps` will be ignored.").concat(msg));
  });
}
export function isRequiredForA11y(props, propName, componentName) {
  warn(props[propName] != null, "The prop `".concat(propName, "` is required to make `").concat(componentName, "` ") + 'accessible for users of assistive technologies such as screen readers.');
}
export function labelKeyType(_ref4) {
  var allowNew = _ref4.allowNew,
      labelKey = _ref4.labelKey;
  warn(!(isFunction(labelKey) && allowNew), '`labelKey` must be a string when `allowNew={true}`.');
}
export var optionType = PropTypes.oneOfType([PropTypes.object, PropTypes.string]);
export function selectedType(_ref5) {
  var multiple = _ref5.multiple,
      onChange = _ref5.onChange,
      selected = _ref5.selected;
  warn(multiple || !selected || selected.length <= 1, 'You are passing multiple options to the `selected` prop of a Typeahead ' + 'in single-select mode. This may lead to unexpected behaviors or errors.');
  warn(!selected || selected && isFunction(onChange), 'You provided a `selected` prop without an `onChange` handler. If you ' + 'want the typeahead to be uncontrolled, use `defaultSelected`. ' + 'Otherwise, set `onChange`.');
}